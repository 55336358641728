import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import HierarchyIcon from 'src/components/common/Icons/Hierarchy';
import RccBoundary from 'src/components/common/RccBoundary/RccBoundary';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultSwitcherConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { SwitcherOptions, useOrganizationContext } from 'src/contexts';
import { useOrganizationsCacheContextInternal } from 'src/contexts/OrganizationsCacheContext';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { OrganizationSwitcherDialog } from '../OrganizationSwitcherDialog/OrganizationSwitcherDialog';
import { OrganizationSwitcherDrawer } from './OrganizationSwitcherDrawer';
import { OrganizationSwitcherMenu } from './OrganizationSwitcherMenu';
import { SwitcherStartIcon } from 'src/components/common/Switcher/SwitcherStartIcon';

export const OrganizationSwitcher = ({
    config = getDefaultSwitcherConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const {
        isLoading: isLoadingProvider,
        isLoadingOrg,
        organization,
        userOrganizations,
        updateOrganization,
    } = useOrganizationContext();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const { isRecentOrganizationsLoading, isRecentOrganizationsMutating } =
        useOrganizationsCacheContextInternal();
    const isMobile = useMobileView();
    const isEmpty = !isLoadingProvider && userOrganizations.length === 0;
    const isLoadingRecents = isRecentOrganizationsMutating || isRecentOrganizationsLoading;
    const isLoading = (isLoadingRecents || isLoadingOrg) && !isEmpty;

    const handleOpenOrgMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseOrgMenu = () => {
        setMenuOpen(false);
    };

    const handleOrgClick = (orgName: string) => {
        handleCloseOrgMenu();
        updateOrganization(orgName);
    };

    let switcherLabel: ReactNode;
    if (isEmpty) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else if (!!organization) {
        switcherLabel = organization?.organizationName;
    } else {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.SELECT');
    }

    return (
        <RccBoundary>
            <SwitcherProvider config={config || {}}>
                <Switcher
                    label={switcherLabel}
                    disabled={isLoadingProvider}
                    isReadOnly={!isLoading && userOrganizations.length == 1}
                    isLoading={isLoading}
                    isEmpty={isEmpty}
                    onClick={handleOpenOrgMenu}
                    startIcon={
                        <SwitcherStartIcon startIcon={<HierarchyIcon />} isLoading={isLoading} />
                    }
                    labelSx={{
                        display: isMobile ? 'none' : undefined,
                    }}
                    showEndIcon={!isMobile}
                    ref={buttonRef}
                    data-element-id='org-switcher-nav-button'
                />
                {isMobile ? (
                    <OrganizationSwitcherDrawer
                        menuOpen={menuOpen}
                        onMenuOpen={handleOpenOrgMenu}
                        onMenuClose={handleCloseOrgMenu}
                        onOrgClick={handleOrgClick}
                    />
                ) : (
                    <OrganizationSwitcherMenu
                        anchorRef={buttonRef}
                        menuOpen={menuOpen}
                        onMenuClose={handleCloseOrgMenu}
                        onOrgClick={handleOrgClick}
                    />
                )}
                <OrganizationSwitcherDialog />
            </SwitcherProvider>
        </RccBoundary>
    );
};
