import { useCallback, useEffect, useState } from 'react';
import { AppConstants } from 'src/constants/app';
import { Global, HomeAppName } from 'src/constants/global';
import { useRccContext } from 'src/contexts';
import { ApiError, Organization } from 'src/types';
import { ApplicationGrantModel, ApplicationListCache, CoreApplicationModel, SharedApplicationModel } from 'src/types/application.type';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { get } from './common.fetchers';

const hourMilliseconds = 60000 * 60;
const homeApplicationsCacheUrl = () => `${Global.Services.FrontDoor}/applications-cache`;
const homeApplicationsUrl = () => `${Global.Services.FrontDoor}/applications`;

export const findHomeAppIndex = <T extends SharedApplicationModel>(apps: T[]): number => {
    return apps.findIndex((a) => a.name === HomeAppName);
}

const sortApps = (userApps: SharedApplicationModel[]) => {
    return userApps.sort((a, b) => {
        const aName = (a.displayName || a.name).toLowerCase();
        const bName = (b.displayName || b.name).toLowerCase();

        if (aName < bName) {
            return -1;
        }
        if (aName > bName) {
            return 1;
        }
        return 0;
    });
}

const sortHomeApp = <T extends SharedApplicationModel>(userApps: T[]): T[] => {
    const homeAppIndex: number = findHomeAppIndex(userApps);
    if (homeAppIndex !== -1) {
        const homeApp = userApps.splice(homeAppIndex, 1)[0];
        userApps.unshift(homeApp);
    }
    return userApps;
}

const getApplicationsForUser = (key: string): Promise<ApplicationGrantModel[]> => {
    return get<ApplicationGrantModel[]>(key)
        .then(sortApps)
        .then(sortHomeApp)
        .catch(DefaultErrorHandler);
};

const fetchAllApplications = (): Promise<CoreApplicationModel[]> => {
    return get<CoreApplicationModel[]>(`${homeApplicationsUrl()}/all-applications`)
        .then(sortApps)
        .then(sortHomeApp<CoreApplicationModel>)
        .catch(DefaultErrorHandler);
};

export const useGetAllApplications = () => {
    const { isAuthenticated } = useRccContext();
    const [data, setData] = useState<CoreApplicationModel[]>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ApiError>();

    const getAllCoreApplications = useCallback(async () => {
        try {
            setIsLoading(true);
            const apps = await fetchAllApplications()
            setData(apps);
            localStorage.setItem(AppConstants.ApplicationsListCacheKey, JSON.stringify({ applications: apps, lastUpdated: new Date().getTime() }));
        } catch (e: unknown) {
            setError(e as ApiError);
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, setData])

    useEffect(function checkCache() {
        if (isAuthenticated === undefined || isAuthenticated) {
            return;
        }

        const serializedEntry = localStorage.getItem(AppConstants.ApplicationsListCacheKey);
        if (!serializedEntry) {
            getAllCoreApplications();
            return;
        }

        const cacheEntry = JSON.parse(serializedEntry) as ApplicationListCache;
        if (!cacheEntry.applications || isNaN(cacheEntry.lastUpdated)) {
            getAllCoreApplications();
            return;
        }

        const currentTime = new Date().getTime();
        const deltaTime = currentTime - cacheEntry.lastUpdated;

        if (deltaTime > hourMilliseconds) {
            getAllCoreApplications();
        } else {
            setData(cacheEntry.applications);
        }
    }, [isAuthenticated, getAllCoreApplications, setData])

    return {
        data: data,
        isLoading,
        error
    }
}

export const useGetApplicationsForUser = (
    organization: Organization,
    config: SWRConfiguration<ApplicationGrantModel[]> = {},
) => {
    const {
        data,
        isLoading,
        error,
    } = useSWRImmutable(!!organization?.organizationName ? [`${homeApplicationsCacheUrl()}/grants`, organization?.organizationName] : null,
        ([key]) => getApplicationsForUser(key),
        config
    );

    return {
        data,
        isLoading,
        error,
    };
};

