import { SwitcherDialog } from 'src/components/common/Switcher/SwitcherDialog';
import { useApplicationsContextInternal } from 'src/contexts/ApplicationsContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationAllAppsList } from './ApplicationAllAppsList';
import { useRecentApplications } from 'src/fetchers/user-recent-applications.fetcher';
import { useOrganizationContext } from 'src/contexts';

export const ApplicationSwitcherDialog = () => {
    const { t } = useTranslationNs();
    const { isAppDialogOpen, setIsAppDialogOpen } = useApplicationsContextInternal();
    const { organization } = useOrganizationContext();

    const { pushRecentApplication } = useRecentApplications();

    const handleAppClick = (selectedAppName: string) => {
        pushRecentApplication(selectedAppName, organization);
        setIsAppDialogOpen(false);
    };

    const handleDialogClose = () => {
        setIsAppDialogOpen(false);
    };

    return (
        <SwitcherDialog
            isOpen={isAppDialogOpen}
            dialogTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            dialogCloseTitle={t('APPLICATION_CONTEXT.ACCESSIBILITY.CLOSE')}
            onClose={handleDialogClose}
        >
            <ApplicationAllAppsList onAppClick={handleAppClick} />
        </SwitcherDialog>
    );
};
