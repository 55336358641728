import { ExtendButtonBaseTypeMap, MenuItem, MenuItemProps, MenuItemTypeMap } from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { getSwitcherStatesSx } from './SwitcherItemSx';

export const SwitcherMenuItem = (
    props: { href?: string } & OverrideProps<ExtendButtonBaseTypeMap<MenuItemTypeMap>, 'a'> &
        MenuItemProps,
) => {
    const { children, sx, ...rest } = props;
    return (
        <MenuItem
            sx={{
                margin: '4px 12px',
                padding: '8px',
                borderRadius: '8px',
                ...getSwitcherStatesSx(),
                ...sx,
            }}
            {...rest}
        >
            {children}
        </MenuItem>
    );
};
