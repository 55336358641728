import { UnfoldMore } from '@mui/icons-material';
import { Box, Button, SxProps, Theme, useTheme } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useSwitcherContext } from 'src/contexts/SwitcherContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { SwitcherStartIcon } from './SwitcherStartIcon';

export type SwitcherProps = {
    label: ReactNode;
    isLoading: boolean;
    isReadOnly: boolean;
    isEmpty: boolean;
    onClick: () => void;
    showEndIcon?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    disabled?: boolean;
    buttonSx?: SxProps<Theme>;
    labelSx?: SxProps<Theme>;
};

/**
 * Switcher component is used as the base for any switcher (org, app, etc.)
 *
 * The switcher will have 7 states:
 * 1. Read only
 * 2. Hover
 * 3. Selected
 * 4. Focus
 * 5. Empty
 * 6. Loading
 * 7. Default
 *
 * States 2, 3, and 4
 * - Are interactive states.
 * - These are dictated by the browser
 *
 * States 1, 5, and 6
 * - Are entity related states.
 * - These are dictated based on data (e.g. organizations, applications, etc.)
 *
 */
export const Switcher = forwardRef<HTMLButtonElement, SwitcherProps>(
    (
        {
            label,
            isLoading,
            isReadOnly,
            isEmpty,
            disabled,
            onClick,
            buttonSx = {},
            labelSx = {},
            startIcon,
            endIcon = (
                <UnfoldMore
                    fontSize='small'
                    sx={{
                        color: (theme) => theme.palette.action.active,
                        height: '24px',
                        width: '24px',
                    }}
                />
            ),
            showEndIcon = true,
            ...rest
        }: SwitcherProps,
        ref,
    ) => {
        const { t } = useTranslationNs();
        const theme = useTheme();
        const { showStartIconOnly, isDisabled: configIsDisabled } = useSwitcherContext();

        return (
            <Button
                disableRipple
                ref={ref as ForwardedRef<HTMLButtonElement>}
                onClick={onClick}
                variant='text'
                endIcon={!isReadOnly && !showStartIconOnly && showEndIcon ? endIcon : null}
                sx={{
                    height: '40px',
                    borderRadius: ThemeValues.BorderRadius,
                    textTransform: 'none',
                    color: theme.palette.text.primary,
                    padding: '0px 4px',
                    '&:focus-visible': {
                        outline: `2px solid ${theme.palette.text.primary}`,
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                    '&:active': {
                        backgroundColor: theme.palette.action.selected,
                    },
                    '.MuiButton-startIcon, .MuiButton-endIcon': {
                        margin: 0,
                    },
                    '&:not(.Mui-disabled) .MuiButton-startIcon': {
                        color: theme.palette.action.active,
                    },
                    '&.Mui-disabled': {
                        '& > #switcher-label': {
                            fontWeight: 500,
                            color: theme.palette.action.active,
                        },
                        '.MuiButton-startIcon, .MuiButton-endIcon': {
                            svg: {
                                color: 'inherit',
                            },
                        },
                    },
                    minWidth: '40px',
                    maxWidth: '224px',
                    ...buttonSx,
                }}
                disabled={disabled || isLoading || isReadOnly || isEmpty || configIsDisabled}
                startIcon={!isEmpty && (startIcon || <SwitcherStartIcon isLoading={isLoading} />)}
                {...rest}
            >
                {!showStartIconOnly && (
                    <Box
                        id='switcher-label'
                        sx={{
                            ...theme.typography.subtitle1,
                            textOverflow: 'ellipsis',
                            textAlign: 'left',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            overflow: 'hidden',
                            color: 'inherit',
                            fontWeight: 600,
                            paddingLeft: '8px',
                            paddingRight: '4px',
                            ...labelSx,
                        }}
                        aria-live='assertive'
                        aria-atomic='true'
                    >
                        {isLoading ? t('COMMON.LOADING') : label}
                    </Box>
                )}
            </Button>
        );
    },
);
