import { AppConstants } from 'src/constants/app';
import { EffectiveOrganizationsResponse, Organization } from 'src/types';
import { SwrStaticKeys } from 'src/types/swr-keys.type';
import useSWR, { Fetcher, SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';
import { Global } from '../constants/global';
import { getOrgShortName } from '../utils/organization-service.utils';
import { DefaultErrorHandler } from '../utils/services.utils';
import { deletef, get } from './common.fetchers';

const frontDoorUrl = () => Global.Services.FrontDoor;

const deleteUserEffectiveOrganizationsCache = (key: string): Promise<void> => {
    return deletef<void>(`${frontDoorUrl()}${key}`, {
        headers: {
            [AppConstants.OrgHeader]: null,
        },
    }).catch(DefaultErrorHandler);
};

const fetchOrgByName: Fetcher<Organization, [key: string, orgName: string]> = ([
    key,
    orgName,
]): Promise<Organization> => {
    return get<Organization>(`${frontDoorUrl()}/organizations${key}`, {
        headers: {
            //Overwrite stored header
            [AppConstants.OrgHeader]: orgName,
        },
    })
        .then((org: Organization) => {
            org.organizationName = getOrgShortName(org.organizationName);
            return org;
        })
        .catch(DefaultErrorHandler);
};

const fetchUserEffectiveOrganizations = (): Promise<EffectiveOrganizationsResponse> => {
    return get<EffectiveOrganizationsResponse>(`${frontDoorUrl()}/organizations/effective`).catch(
        DefaultErrorHandler,
    );
};

export const useDeleteUserEffectiveOrganizationsCache = (): (() => Promise<void>) => {
    const { trigger: deleteEffectiveOrgsCache } = useSWRMutation(
        '/organizations/effective',
        deleteUserEffectiveOrganizationsCache,
        {
            revalidate: false,
        },
    );

    return deleteEffectiveOrgsCache;
};

const useGetOrganizationByName = (orgName: string, config: SWRConfiguration<Organization> = {}) => {
    const {
        data: organizationData,
        isLoading: isLoadingOrg,
        error: errorOrg,
    } = useSWR(
        orgName ? [`/organization/${orgName}`, orgName] : null,
        (args) => fetchOrgByName(args),
        {
            /**
             * https://swr.vercel.app/docs/advanced/performance#deduplication
             * Allows deduping of requests with the same key in this time span of miliseconds
             * We set it to 10 seconds since we don't expect changes in the response too often
             */
            dedupingInterval: 10000,
            ...config,
        },
    );

    return {
        organizationData,
        isLoadingOrg,
        errorOrg,
    };
};

const useGetEffectiveOrganizations = (
    isAuthenticated: boolean,
    config: SWRConfiguration<EffectiveOrganizationsResponse> = {},
) => {
    const {
        data: effectiveOrganizationsResponse,
        isLoading: isLoadingEffectiveOrgs,
        error: errorOrgs,
    } = useSWRImmutable(
        isAuthenticated ? SwrStaticKeys.ALL_ORGS : null,
        fetchUserEffectiveOrganizations,
        config,
    );

    if (!!effectiveOrganizationsResponse?.organizations) {
        effectiveOrganizationsResponse.organizations.sort((a, b) => {
            const aName = a.organizationName;
            const bName = b.organizationName;
            return aName.localeCompare(bName);
        });
    }

    return {
        effectiveOrganizationsResponse,
        isLoadingEffectiveOrgs,
        errorOrgs,
    };
};

export { useGetEffectiveOrganizations, useGetOrganizationByName };

