import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import {
    EmailChallengeTransaction,
    EmailTransaction,
    saveTransactionMeta,
    Status,
} from '@okta/okta-auth-js';
import EnterEmail from './Steps/EnterEmail';
import EnterVerificationCode from './Steps/EnterVerificationCode';
import { useRccContext } from 'src/contexts';
import { oktaService } from 'src/fetchers/okta.fetchers';
import { DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { CommonDialog } from 'src/components/common/Dialog/CommonDialog';
import { ThemeValues } from 'src/constants/theme-values';
import useMobileView from 'src/hooks/useMobileView';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';
import { rccError } from 'src/utils/error.utils';

interface EmailChallengeTransactionMetadata {
    emailTransactionId: string;
    challengeId: string;
}

const UpdateEmailModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { t } = useTranslationNs({ keyPrefix: 'USER_SETTINGS.PROFILE.EMAIL_UPDATE' });
    const { oktaAuth } = useOktaAuth();

    const [emailTransactionId, setEmailTransactionId] = useState<string>();
    const [challengeId, setChallengeId] = useState<string>();
    const [codeExpired, setCodeExpired] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const { userInfo } = useRccContext();

    const [step, setStep] = useState<'email' | 'verify'>(
        userInfo.email_verified ? 'email' : 'verify',
    );

    const isMobileView = useMobileView();

    const { setSnackOptions } = useSnackbarContext();

    const { refreshUserInfo } = useRccContext();

    useEffect(function resumeUpdateEmailFlow() {
        (async () => {
            if (userInfo.email_verified) {
                return;
            }

            let metadata = oktaAuth.transactionManager.load();

            let challengeTransaction = metadata as unknown as EmailChallengeTransactionMetadata;

            if (challengeTransaction) {
                setEmailTransactionId(challengeTransaction.emailTransactionId);
                setChallengeId(challengeTransaction.challengeId);
                return;
            }

            let hasReqsToVerify = emailTransactionId && challengeId;
            if (step === 'verify' && !hasReqsToVerify) {
                await handleUpdateEmail(userInfo.preferred_username);
            }
        })();
    }, []);

    useEffect(
        function manageOktaTransactionMetadata() {
            let metadata: EmailChallengeTransactionMetadata = {
                emailTransactionId: emailTransactionId,
                challengeId: challengeId,
            };

            saveTransactionMeta(oktaAuth, metadata);
        },
        [emailTransactionId, challengeId],
    );

    const handleClose = () => {
        onClose();
    };

    const handleUpdateEmail = async (newEmail: string) => {
        oktaService
            .updateUserEmail(oktaAuth, newEmail)
            .then((res: EmailTransaction) => {
                refreshUserInfo();
                if (res.id) {
                    let emailTransactionId = res.id;

                    setEmailTransactionId(emailTransactionId);
                    handleSendVerificationEmail(emailTransactionId);
                }
                setStep('verify');
            })
            .catch(() => {
                setSnackOptions({
                    color: 'error',
                    message: t('ADD_EMAIL.ERROR').toString(),
                    autoHideDuration: null,
                });
            });
    };

    const verifyUserEmailChallenge = async (verificationCode: string) => {
        if (emailTransactionId && challengeId) {
            await oktaService.verifyUserEmailChallenge(
                oktaAuth,
                emailTransactionId,
                challengeId,
                verificationCode,
            );
        } else {
            throw rccError('Resource not found');
        }
    };

    const verifyUserEmail = async (
        verificationCode: string,
        emailTransaction: EmailTransaction | EmailChallengeTransaction,
    ) => {
        if (emailTransaction.status !== Status.UNVERIFIED) return;
        if (emailTransaction.verify) {
            await oktaService.verifyUserEmail(verificationCode, emailTransaction.verify);
        } else {
            await verifyUserEmailChallenge(verificationCode);
        }
    };

    const handleVerifyUserEmail = async (verificationCode: string) => {
        try {
            await verifyUserEmailChallenge(verificationCode);

            setCodeExpired(false);
            await refreshUserInfo();

            setSnackOptions({
                color: 'success',
                message: t('VERIFY_CODE.SUCCESS').toString(),
                autoHideDuration: null,
            });

            handleClose();
        } catch (err: any) {
            if (err.message.includes('Resource not found')) {
                setCodeExpired(true);
                setSnackOptions({
                    color: 'error',
                    message: t('VERIFY_CODE.ERROR.EXPIRED').toString(),
                    autoHideDuration: null,
                });
            } else {
                setInvalidCode(true);
                setSnackOptions({
                    color: 'error',
                    message: t('VERIFY_CODE.ERROR.WRONG_CODE').toString(),
                    autoHideDuration: null,
                });
            }
        }
    };

    const sendVerificationEmail = (id?: string) => {
        if (id) {
            return oktaService.sendUserEmailChallenge(oktaAuth, id);
        }

        if (emailTransactionId) {
            return oktaService.sendUserEmailChallenge(oktaAuth, emailTransactionId);
        }

        return oktaService.sendUserEmailChallenge(oktaAuth, challengeId);
    };

    const handleSendVerificationEmail = (id?: string) => {
        sendVerificationEmail(id)
            .then((res: EmailChallengeTransaction) => {
                setChallengeId(res.id);

                setCodeExpired(false);
                setSnackOptions({
                    color: 'success',
                    message: t('ADD_EMAIL.SUCCESS').toString(),
                    autoHideDuration: null,
                });
            })
            .catch((err) => {
                setSnackOptions({
                    color: 'error',
                    message: err.message,
                    autoHideDuration: null,
                });
            });
    };

    const steps = {
        email: (
            <EnterEmail
                currEmail={userInfo.email}
                handleUpdateEmail={handleUpdateEmail}
                key={'Enter Email'}
            />
        ),
        verify: (
            <EnterVerificationCode
                verifyCode={handleVerifyUserEmail}
                handleSendVerificationEmail={handleSendVerificationEmail}
                codeExpired={codeExpired}
                invalidCode={invalidCode}
                setInvalidCode={setInvalidCode}
                key={'Enter Verification Code'}
            />
        ),
    };

    return (
        <CommonDialog onClose={handleClose} open={open}>
            <DialogTitle>
                <Typography variant='h5' fontWeight='600'>
                    {step === 'email' ? t('UPDATE') : t('VERIFY')}
                </Typography>
            </DialogTitle>
            <DialogContent
                sx={{
                    width: isMobileView ? ThemeValues.MobileDialogWidth : '480px',
                }}
            >
                <Stack gap='16px'>{steps[step]}</Stack>
            </DialogContent>
        </CommonDialog>
    );
};

export default UpdateEmailModal;
