import Close from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, Stack, Theme, SxProps } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ReactNode, forwardRef, useMemo } from 'react';
import useMobileView from 'src/hooks/useMobileView';
import { getScrollBarWidth } from 'src/utils/dom.utils';

const MobileTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

const WebTransition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Fade ref={ref} {...props} />;
});

export const SwitcherDialog = ({
    isOpen,
    dialogTitle,
    dialogCloseTitle,
    dialogTitleSlot,
    onClose,
    children,
}: {
    isOpen: boolean;
    dialogTitle: string;
    dialogCloseTitle: string;
    dialogTitleSlot?: ReactNode;
    onClose: () => void;
    children: ReactNode;
}) => {
    const isMobileView = useMobileView();
    const scrollbarWidth = useMemo(() => {
        return getScrollBarWidth();
    }, []);
    const mobilePaperStyles: SxProps = isMobileView
        ? {
              height: '100%',
              width: '100%',
              margin: 0,
              maxHeight: 'unset',
              borderRadius: 0,
          }
        : {};
    const dialogHeaderStyles = isMobileView
        ? {
              padding: '20px 16px',
          }
        : {
              padding: '24px',
          };
    const dialogContentStyles = isMobileView
        ? {
              padding: `0px 16px 8px 16px`,
          }
        : {
              padding: `0px ${Math.max(24 - scrollbarWidth, 0)}px 24px 24px`,
          };

    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '12px',
                    width: '452px',
                    ...mobilePaperStyles,
                },
            }}
            open={isOpen}
            onClose={onClose}
            TransitionComponent={isMobileView ? MobileTransition : WebTransition}
        >
            <Stack>
                <Stack
                    position='relative'
                    justifyContent='space-between'
                    alignItems='center'
                    direction='row'
                    sx={dialogHeaderStyles}
                >
                    <DialogTitle
                        sx={{ padding: 0, fontSize: '18px', fontWeight: 700 }}
                        variant='h6'
                    >
                        {dialogTitle}
                    </DialogTitle>
                    <IconButton sx={{ padding: '6px' }} title={dialogCloseTitle} onClick={onClose}>
                        <Close sx={{ height: '20px', width: '20px' }} />
                    </IconButton>
                </Stack>
                {!!dialogTitleSlot && dialogTitleSlot}
            </Stack>
            <DialogContent sx={{ ...dialogContentStyles, scrollbarGutter: 'stable' }}>
                {children}
            </DialogContent>
        </Dialog>
    );
};
