import { SvgIcon, useTheme } from '@mui/material';

const HierarchyIcon = () => {
    const theme = useTheme();

    return (
        <SvgIcon
            sx={{
                height: '24px',
                width: '24px',
                fill: 'currentColor',
            }}
        >
            <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <rect x='8.5' y='3' width='7' height='6' rx='1.5' />
                <rect x='2.5' y='15' width='7' height='6' rx='1.5' />
                <rect x='14.5' y='15' width='7' height='6' rx='1.5' />
                <path d='M8 13C7.44772 13 7 13.4477 7 14V15H5V14C5 12.3431 6.34315 11 8 11H16C17.6569 11 19 12.3431 19 14V15H17V14C17 13.4477 16.5523 13 16 13H8Z' />
                <rect x='11' y='9' width='2' height='2' />
            </svg>
        </SvgIcon>
    );
};

export default HierarchyIcon;
