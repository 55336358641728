import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';

export type IOrganizationDialogContext = {
    handleOrganizationClick: (value: string) => void;
};

export const OrganizationDialogContext = createContext<IOrganizationDialogContext | undefined>(
    undefined,
);

export const useOrganizationDialogContext = (): IOrganizationDialogContext => {
    const value = useContext(OrganizationDialogContext);

    if (!value) {
        throw new Error(
            'To use OrganizationDialogContext, it must be wrapped in <OrganizationDialogProvider/>',
        );
    }

    return value;
};

export const OrganizationDialogProvider = ({
    updateOrganization,
    setIsOrgDialogOpen,
    children,
}: {
    updateOrganization: (org: string) => void;
    setIsOrgDialogOpen: Dispatch<SetStateAction<boolean>>;
    children: ReactNode;
}) => {
    const handleOrganizationClick = useCallback(
        (value: string) => {
            setIsOrgDialogOpen(false);
            updateOrganization(value);
        },
        [setIsOrgDialogOpen, updateOrganization],
    );

    const contextValue = useMemo(
        () => ({
            handleOrganizationClick,
        }),
        [handleOrganizationClick],
    );

    return (
        <OrganizationDialogContext.Provider value={contextValue}>
            {children}
        </OrganizationDialogContext.Provider>
    );
};
