import { PopoverOrigin } from '@mui/material';
import { createContext, useContext } from 'react';

export type SwitcherOptions = {
    menuAnchorOrigin?: PopoverOrigin;
    menuTransformOrigin?: PopoverOrigin;
    showStartIconOnly?: boolean;
    isDisabled?: boolean;
};

export const SwitcherContext = createContext<SwitcherOptions | undefined>(undefined);

export const useSwitcherContext = (): SwitcherOptions => {
    const value = useContext(SwitcherContext);

    if (!value) {
        throw new Error('To use SwitcherContext, it must be wrapped in <SwitcherProvider/>');
    }

    return value;
};
