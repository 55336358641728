import { RccEventEmitter } from 'src/classes/event-emitter';
import { AppEnv, RccEnvironment, ServiceTypeEndpoints } from '../types';

export const Global: AppEnv = {
    Services: null,
    AccessToken: '',
    NepOrganization: '',
    IdmUrl: null,
    Environment: null,
};

export const FrontDoorProxyUrl = () => `${Global.Services.FrontDoor}/proxy`;

export const IdentityAppName = 'IDENTITY';
export const HomeAppName = 'HOME';

type RccEnvironmentSubset = Extract<RccEnvironment, 'dev' | 'stg' | 'prd' | 'local'>;

export const IDMUrlsByEnv: Record<RccEnvironmentSubset, string> = {
    local: 'https://id-dev.ncrcloud.com',
    dev: 'https://id-dev.ncrcloud.com',
    stg: 'https://id-stg.ncrcloud.com',
    prd: 'https://identity.ncr.com',
};

/*
* **IMPORTANT**
*
* Please double check ALL DNS changes made below.
* 
* To verify Home app DNS records, please check the following file:
* https://github.com/ncrvoyix-swt-retail/retail-infra-gcp/blob/main/projects/Retail/ret-cbs-global-shared/dns/ncrcloud.com/devex.yaml
* 
*/
export const ApigeeGateways: Record<RccEnvironment, ServiceTypeEndpoints> = {
    local: {
        Provisioning: 'https://gateway-dev-x.ncrcloud.com/provisioning',
        FrontDoor: 'https://localhost:4001/api',
        Users: 'https://gateway-dev-x.ncrcloud.com/users',
    },
    dev: {
        Provisioning: 'https://gateway-dev-x.ncrcloud.com/provisioning',
        FrontDoor: 'https://home-dev.ncrcloud.com/api',
        Users: 'https://gateway-dev-x.ncrcloud.com/users',
    },
    stg: {
        Provisioning: 'https://gateway-staging-x.ncrcloud.com/provisioning',
        FrontDoor: 'https://home-stg.ncrcloud.com/api',
        Users: 'https://gateway-staging-x.ncrcloud.com/users',
    },
    prd: {
        Provisioning: 'https://api.ncr.com/provisioning',
        FrontDoor: 'https://home-prd.ncrcloud.com/api',
        Users: 'https://api.ncr.com/users',
    },
    napac: {
        Provisioning: 'https://api-napac.ncr.com/provisioning',
        FrontDoor: 'https://home-prd-napac.ncrcloud.com/api',
        Users: 'https://api-napac.ncr.com/users',
    },
    apac: {
        Provisioning: 'https://api-apac.ncr.com/provisioning',
        FrontDoor: 'https://home-prd-apac.ncrcloud.com/api',
        Users: 'https://api-apac.ncr.com/users',
    },
    emea: {
        Provisioning: 'https://api-emea.ncr.com/provisioning',
        FrontDoor: 'https://home-prd-emea.ncrcloud.com/api',
        Users: 'https://api-emea.ncr.com/users',
    },
};

export const GlobalEmitter = new RccEventEmitter();
