import { List, Typography } from '@mui/material';
import { DialogListItemActionButton } from 'src/components/common/Dialog/DialogListItemActionButton';
import { useOrganizationContext } from 'src/contexts';
import { useOrganizationDialogContext } from '../OrganizationProviders/OrganizationDialogProvider';

export const OrganizationAllOrgsList = () => {
    const { userOrganizations, organization } = useOrganizationContext();
    const { handleOrganizationClick } = useOrganizationDialogContext();

    return (
        <List disablePadding>
            {userOrganizations.map((org) => {
                const orgName = org.organizationName;

                return (
                    <DialogListItemActionButton
                        key={orgName}
                        selected={orgName === organization?.organizationName}
                        buttonSx={{
                            padding: '12px 8px 12px 12px',
                        }}
                        onListItemButtonClick={() => handleOrganizationClick(orgName)}
                    >
                        <Typography variant='body1'>{orgName}</Typography>
                    </DialogListItemActionButton>
                );
            })}
        </List>
    );
};
