import { Box, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { CustomColors } from 'src/constants/theme-values';

export const SwipeableDrawerHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Box height='auto' paddingTop='6px' width='100%'>
            <Box
                sx={(theme: Theme) => ({
                    margin: '0px auto',
                    width: '48px',
                    height: '5px',
                    backgroundColor: CustomColors.otherSurfaceBorder[theme.palette.mode],
                    borderRadius: '33px',
                })}
            />
            {children}
        </Box>
    );
};
