import { useCallback, useState } from 'react';
import { AppConstants } from 'src/constants/app';
import { Global } from 'src/constants/global';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import { ApiError, Organization } from '..';
import { post } from './common.fetchers';

const baseUrl = () => Global.Services.FrontDoor;

const pushRecentApplicationFetcher = (
    key: string,
    applicationName: string,
    organization: Organization,
): Promise<void> => {
    return post<void>(
        `${baseUrl()}${key}`,
        {
            applicationName: applicationName,
        },
        {
            headers: {
                [AppConstants.OrgHeader]: organization.organizationName,
            },
        },
    ).catch(DefaultErrorHandler);
};

export const useRecentApplications = () => {
    const key = '/applications-cache/recent';
    const [recentApplicationsMutationError, setRecentApplicationsMutationError] =
        useState<ApiError>();
    const [isRecentApplicationsMutating, setIsRecentApplicationsMutating] =
        useState<boolean>(false);

    const pushRecentApplication = useCallback(
        async (applicationName: string, organization: Organization) => {
            try {
                setIsRecentApplicationsMutating(true);
                await pushRecentApplicationFetcher(key, applicationName, organization).catch(
                    DefaultErrorHandler,
                );
                setIsRecentApplicationsMutating(false);
            } catch (e: unknown) {
                setRecentApplicationsMutationError(e as ApiError);
            }
        },
        [setIsRecentApplicationsMutating, setRecentApplicationsMutationError],
    );

    return {
        pushRecentApplication,
        recentApplicationsMutationError,
        isRecentApplicationsMutating,
    };
};
