import { ReactElement } from 'react';
import { LinkProps } from 'src/types/router.type';
import { isInternalEmail } from 'src/utils/form.utils';

export type UserProfileOption = {
    label: string;
    icon?: ReactElement;
    url?: string;
    hidden?: boolean;
    onClick?: () => void;
    linkProps?: Omit<LinkProps, 'to' | 'onClick'>;
};

export type ResetPasswordDto = {
    oldPassword: string;
    newPassword: string;
};

export interface UserProfile {
    username: string;
    fullName: string;
    status: UserStatus;
    email: string;
    givenName: string;
    familyName: string;
    primary: boolean;
    displayName: string;
    displayUsername: string;
    telephoneNumber: string | null;
    dateOfBirth: string | null; // Format: YYYY-MM-DD
    address: UserAddress;
    synced: boolean;
    federationType: UserFederationType;
    externalId: string;
    lastStatusChangeTimestamp: Date;
}

export enum UserFederationType {
    FULL = 'FULL',
    PARTIAL = 'PARTIAL',
    NONE = 'NONE',
}

export interface UserAddress {
    city: string | null;
    country: string | null;
    postalCode: string | null;
    state: string | null;
    street: string | null;
    addressLine2: string | null;
    county: string | null;
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    TERMINATED = 'TERMINATED',
    SUSPENDED = 'SUSPENDED',
}

export function isFederated(user?: UserProfile): boolean {
    if (user === undefined || user === null) {
        return false;
    }

    if (isInternalEmail(user.email)) {
        return true;
    }

    return (
        user.federationType === UserFederationType.FULL ||
        user.federationType === UserFederationType.PARTIAL
    );
}

export function getFormattedAddress(address: UserAddress): string {
    const line1 = [address.street, address.addressLine2].filter((line) => line).join(' ');

    const line2 = `${address.city ?? ''}${
        address.city && (address.state || address.postalCode) ? ', ' : ''
    }${address.state ?? ''}${address.state && address.postalCode ? ' ' : ''}${
        address.postalCode ?? ''
    }`;

    const line3 = address.country;

    return [line1, line2, line3].filter((line) => line).join('\n');
}
