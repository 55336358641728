import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { CommonDialog } from 'src/components/common/Dialog/CommonDialog';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ThemeValues } from 'src/constants/theme-values';

interface ConfirmationDialogProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onConfirm: () => void;
    onClose: () => void;
    title?: ReactNode;
    contentText?: ReactNode;
    cancelActionText?: ReactNode;
    confirmActionText?: ReactNode;
}

const ConfirmationPrompt = ({
    open,
    setOpen,
    onConfirm,
    onClose,
    title,
    contentText,
    cancelActionText,
    confirmActionText,
}: ConfirmationDialogProps) => {
    const { t } = useTranslationNs();

    const isMobileView = useMobileView();

    const container = window !== undefined ? () => window.document.body : undefined;

    title = title || t('COMMON.CONFIRM.TITLE');
    contentText = contentText || t('COMMON.CONFIRM.CONTENT');
    cancelActionText = cancelActionText || t('COMMON.BUTTONS.CANCEL');
    confirmActionText = confirmActionText || t('COMMON.BUTTONS.CONFIRM');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Box>
            {!isMobileView ? (
                <CommonDialog open={open} onClose={handleClose}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{contentText}</DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, py: 2 }}>
                        <Button onClick={handleClose}>{cancelActionText}</Button>
                        <Button
                            variant='contained'
                            sx={{
                                boxShadow: 'none',
                                ':hover, :active': {
                                    boxShadow: 'none',
                                },
                            }}
                            onClick={handleConfirm}
                        >
                            {confirmActionText}
                        </Button>
                    </DialogActions>
                </CommonDialog>
            ) : (
                <SwipeableDrawer
                    container={container}
                    anchor='bottom'
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    disableDiscovery
                    disableSwipeToOpen
                    sx={{
                        zIndex: ThemeValues.Elevations.Drawer,

                        '.MuiPaper-root.MuiDrawer-paper': {
                            borderTopRightRadius: ThemeValues.BorderRadius,
                            borderTopLeftRadius: ThemeValues.BorderRadius,
                        },
                    }}
                >
                    <Stack sx={{ p: 2 }} gap={2}>
                        <Stack direction='row' alignItems='center'>
                            <Typography variant={'h5'} fontWeight='bold' sx={{ flexGrow: 1 }}>
                                {title}
                            </Typography>
                            <IconButton size='small' onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <Typography>{contentText}</Typography>
                        <Stack direction='column' gap={1}>
                            <Button
                                variant='outlined'
                                onClick={handleClose}
                                sx={{
                                    textTransform: 'unset',
                                }}
                            >
                                {cancelActionText}
                            </Button>
                            <Button
                                variant='contained'
                                sx={{
                                    boxShadow: 'none',
                                    ':hover, :active': {
                                        boxShadow: 'none',
                                    },
                                    textTransform: 'unset',
                                }}
                                onClick={handleConfirm}
                            >
                                {confirmActionText}
                            </Button>
                        </Stack>
                    </Stack>
                </SwipeableDrawer>
            )}
        </Box>
    );
};

export default ConfirmationPrompt;
