import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Global } from 'src/constants/global';
import { IOrganizationContext, useRccContext } from 'src/contexts';
import { OrganizationContext } from 'src/contexts/OrganizationContext';
import { useOrganizationsCacheContextInternal } from 'src/contexts/OrganizationsCacheContext';
import {
    useGetEffectiveOrganizations,
    useGetOrganizationByName,
} from 'src/fetchers/organization.fetchers';
import {
    useGetPreloadDefault,
    useGetPreloadFirstOrganization,
    useGetPreloadSearchQuery,
} from 'src/fetchers/preload-organization.fetchers';
import { BasicOrganizationData, QueryParamKeys } from 'src/types';
import { Organization } from 'src/types/organization.type';
import { getSearchParam, setSearchParam, useLocation } from 'src/utils/search-params.utils';

export const OrganizationContextProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOrg, setIsLoadingOrg] = useState(false);
    const [userOrganizations, setUserOrganizations] = useState<BasicOrganizationData[]>();
    const [totalOrganizations, setTotalOrganizations] = useState<number>();
    const [searchParams, setSearchParams] = useState<URLSearchParams>();
    const [error, setError] = useState();
    const { isAuthenticated } = useRccContext();
    const [organizationName, setOrganizationName] = useState<string>();
    const [organization, setOrganization] = useState<Organization>();
    const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
    const { updateUserRecentOrganizations, isRecentOrganizationsLoading } =
        useOrganizationsCacheContextInternal();
    const { isLoadingEffectiveOrgs, errorOrgs, effectiveOrganizationsResponse } =
        useGetEffectiveOrganizations(isAuthenticated);
    const location = useLocation();
    const currentSearchParam: string = getSearchParam(QueryParamKeys.organization);
    const [preloadId, setPreloadId] = useState(1);
    const [hasPreloaded, setHasPreloaded] = useState(false);

    const updateProviderOrganization = useCallback(
        (org: Organization | null) => {
            Global.NepOrganization = org?.organizationName;
            setOrganization(org);
        },
        [setOrganization],
    );

    useGetPreloadSearchQuery(
        1,
        preloadId,
        setPreloadId,
        updateProviderOrganization,
        setHasPreloaded,
        hasPreloaded,
        currentSearchParam,
    );
    useGetPreloadDefault(
        2,
        preloadId,
        setPreloadId,
        updateProviderOrganization,
        setHasPreloaded,
        hasPreloaded,
    );
    useGetPreloadFirstOrganization(
        3,
        preloadId,
        setPreloadId,
        updateProviderOrganization,
        setHasPreloaded,
        hasPreloaded,
        isLoadingEffectiveOrgs,
        effectiveOrganizationsResponse?.organizations,
    );

    const {
        organizationData,
        isLoadingOrg: isLoadingOrgByName,
        errorOrg,
    } = useGetOrganizationByName(organizationName, {
        keepPreviousData: false,
    });

    useEffect(
        function syncLoadingOrgState() {
            setIsLoadingOrg(isLoadingOrgByName || !hasPreloaded);
        },
        [isLoadingOrgByName, hasPreloaded, setIsLoadingOrg],
    );

    useEffect(
        function setupUserOrganizations() {
            if (!effectiveOrganizationsResponse) return;
            const { organizations, totalResults } = effectiveOrganizationsResponse;
            setUserOrganizations(organizations);
            setTotalOrganizations(totalResults);
        },
        [effectiveOrganizationsResponse, setUserOrganizations, setTotalOrganizations],
    );

    useEffect(
        function setActiveOrganization() {
            if (!organizationData) return;
            updateProviderOrganization(organizationData);
        },
        [organizationData, updateProviderOrganization],
    );

    useEffect(
        function syncUserRecentOrganizations() {
            if (!organizationData || isRecentOrganizationsLoading) return;
            updateUserRecentOrganizations(organizationData);
        },
        [organizationData, isRecentOrganizationsLoading, updateUserRecentOrganizations],
    );

    useEffect(
        function setActiveParams() {
            if (
                organization &&
                getSearchParam(QueryParamKeys.organization) !== organization.organizationName
            ) {
                setSearchParam(QueryParamKeys.organization, organization.organizationName);
                setSearchParams(searchParams);
            }
        },
        [organization, location],
    );

    useEffect(
        function setLoadingState() {
            if (!isAuthenticated || error) {
                setIsLoading(false);
                return;
            }

            setIsLoading(isLoadingOrg || isLoadingEffectiveOrgs);
        },
        [setIsLoading, isAuthenticated, isLoadingOrg, isLoadingEffectiveOrgs, error],
    );

    useEffect(
        function setErrorState() {
            if (errorOrg) {
                updateProviderOrganization(null);
            }

            setError(errorOrg || errorOrgs);
        },
        [errorOrg, errorOrgs, setError],
    );

    const updateOrganizationName = useCallback(
        (org: string) => {
            var normalizedOrgName = org.toLowerCase();
            setOrganizationName((previousOrgName: string) =>
                normalizedOrgName == previousOrgName ? previousOrgName : normalizedOrgName,
            );
        },
        [setOrganizationName],
    );

    useEffect(
        function resetOrganizationData() {
            if (!isAuthenticated) {
                setPreloadId(1);
                setHasPreloaded(false);
                setError(undefined);
                setUserOrganizations(undefined);
                setOrganizationName(undefined);
                setOrganization(undefined);
                setTotalOrganizations(undefined);
                Global.NepOrganization = '';
            }
        },
        [
            isAuthenticated,
            setPreloadId,
            setHasPreloaded,
            setError,
            setUserOrganizations,
            setOrganizationName,
            setOrganization,
            setTotalOrganizations,
        ],
    );

    const contextValue: IOrganizationContext = useMemo(
        () => ({
            organization,
            isOrgDialogOpen,
            setIsOrgDialogOpen,
            userOrganizations: userOrganizations ?? [],
            updateOrganization: updateOrganizationName,
            isLoading,
            isLoadingOrg,
            totalOrganizations,
            hasPreloaded,
            error,
        }),
        [
            organization,
            isOrgDialogOpen,
            setIsOrgDialogOpen,
            userOrganizations,
            updateOrganizationName,
            isLoading,
            isLoadingOrg,
            totalOrganizations,
            hasPreloaded,
            error,
        ],
    );

    return (
        <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>
    );
};
