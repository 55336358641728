import { MutableRefObject, ReactNode, useMemo, useRef, useState } from 'react';
import RccBoundary from 'src/components/common/RccBoundary/RccBoundary';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultSwitcherConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { useOrganizationContext, useRccContext } from 'src/contexts';
import { useApplicationsContextInternal } from 'src/contexts/ApplicationsContext';
import { SwitcherOptions } from 'src/contexts/SwitcherContext';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationSwitcherDialog } from '../ApplicationSwitcherDialog/ApplicationSwitcherDialog';
import { ApplicationSwitcherDrawer } from './ApplicationSwitcherDrawer';
import { ApplicationSwitcherIcon } from './ApplicationSwitcherIcon';
import { ApplicationSwitcherMenu } from './ApplicationSwitcherMenu';
import { SwitcherStartIcon } from 'src/components/common/Switcher/SwitcherStartIcon';
import { ApplicationSwitcherDisplayModeIcon } from './ApplicationSwitcherDisplayModeIcon';

export const ApplicationSwitcher = ({
    isDisplayMode = false,
    config = getDefaultSwitcherConfig(),
    onClick,
    onClose,
}: {
    isDisplayMode?: boolean;
    config?: SwitcherOptions;
    onClick?: () => void;
    onClose?: () => void;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { isAuthenticated } = useRccContext();
    const { isLoadingOrg, error: organizationError } = useOrganizationContext();
    const {
        isLoading: isLoadingApp,
        application,
        error: applicationError,
        getSwitcherList,
    } = useApplicationsContextInternal();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const isMobile = useMobileView();
    const applicationsList = getSwitcherList();

    const handleOpenAppMenu = () => {
        if (onClick) onClick();
        if (!isDisplayMode) setMenuOpen(true);
    };

    const handleCloseAppMenu = () => {
        if (onClose) onClose();
        setMenuOpen(false);
    };

    const appDisplayName = application?.displayName || application?.name;
    const hasNoErrors = !applicationError && !organizationError;
    const isUnauthenticatedLoading = hasNoErrors && isLoadingApp;
    const isAuthenticatedLoading = hasNoErrors && (isLoadingApp || isLoadingOrg);
    const isLoading = isAuthenticated ? isAuthenticatedLoading : isUnauthenticatedLoading;
    const isEmpty = applicationsList.length === 0 && !isLoading;

    let switcherLabel: ReactNode;
    if (isEmpty) switcherLabel = t('APPLICATION_CONTEXT.SWITCHER.EMPTY');
    else if (!!application) switcherLabel = appDisplayName;
    else switcherLabel = t('APPLICATION_CONTEXT.SWITCHER.SELECT');

    const icon = useMemo(
        () => <ApplicationSwitcherIcon imageUrl={application?.imageUrl} appName={appDisplayName} />,
        [application?.imageUrl, appDisplayName],
    );

    return (
        <RccBoundary>
            <SwitcherProvider config={config || {}}>
                <Switcher
                    isReadOnly={!isLoading && applicationsList.length == 1}
                    isLoading={isLoading}
                    isEmpty={isEmpty && !isDisplayMode}
                    onClick={handleOpenAppMenu}
                    ref={buttonRef}
                    data-element-id='app-switcher-nav-button'
                    startIcon={
                        isDisplayMode ? (
                            <ApplicationSwitcherDisplayModeIcon
                                isLoading={isLoading}
                                isEmpty={isEmpty}
                                startIcon={icon}
                            />
                        ) : (
                            <SwitcherStartIcon isLoading={isLoading} startIcon={icon} />
                        )
                    }
                    label={switcherLabel}
                    labelSx={{
                        display: isMobile || isDisplayMode ? 'none' : undefined,
                    }}
                    showEndIcon={!isMobile && !isDisplayMode}
                />
                {isMobile ? (
                    <ApplicationSwitcherDrawer
                        applications={applicationsList}
                        menuOpen={menuOpen}
                        onMenuOpen={handleOpenAppMenu}
                        onMenuClose={handleCloseAppMenu}
                    />
                ) : (
                    <ApplicationSwitcherMenu
                        applications={applicationsList}
                        anchorRef={buttonRef}
                        menuOpen={menuOpen}
                        onMenuClose={handleCloseAppMenu}
                    />
                )}
                <ApplicationSwitcherDialog />
            </SwitcherProvider>
        </RccBoundary>
    );
};
