import { Menu, QuestionMark } from '@mui/icons-material';
import { Box, CircularProgress, Stack, SxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { CustomColors } from 'src/constants/theme-values';

export const ApplicationSwitcherDisplayModeIcon = ({ isLoading, isEmpty, startIcon, sx = {} }) => {
    const theme = useTheme();
    const nonDefaultState = isLoading || isEmpty;
    const nonDefaultStyles: SxProps = nonDefaultState
        ? {
              borderRadius: '8px',
              border: '1px solid',
              borderColor: isEmpty
                  ? 'currentColor'
                  : CustomColors.otherSurfaceBorder[theme.palette.mode],
          }
        : undefined;
    let activeNode: ReactNode;

    if (isLoading) {
        activeNode = (
            <CircularProgress
                sx={{
                    color: theme.palette.action.active,
                }}
                size='16px'
            />
        );
    } else if (isEmpty) {
        activeNode = (
            <QuestionMark
                sx={{
                    height: '20px',
                    width: '20px',
                }}
            />
        );
    } else {
        activeNode = startIcon;
    }

    return (
        <Stack direction='row' alignItems='center' sx={sx}>
            <Stack justifyContent='center' alignItems='center' px='8px'>
                <Menu sx={{ height: '20px', width: '20px' }} />
            </Stack>
            <Box sx={nonDefaultStyles} height='32px' width='32px'>
                {activeNode}
            </Box>
        </Stack>
    );
};
