import { UserClaims } from '@okta/okta-auth-js';
import { createContext, useContext } from 'react';
import { RccEvents } from 'src/classes/event-emitter';
import { rccError } from 'src/utils/error.utils';

export type IRccContext = {
    isAuthenticated: boolean;
    isInternalUser: boolean;
    events: RccEvents;
    userInfo: UserClaims;
    _TEMPORARY_globalProfileOktaSdkEnabled?: boolean;
    refreshUserInfo: () => Promise<void>;
};

export const RccContext = createContext<IRccContext | undefined>(undefined);

export const useRccContext = (): IRccContext => {
    const value = useContext(RccContext);

    if (!value) {
        throw new Error(rccError('To use RccContext, it must be wrapped in <RccContextProvider/>'));
    }

    return value;
};
