import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ApiError, BasicOrganizationData, Organization } from 'src/types';
import { rccError } from 'src/utils/error.utils';

export type IOrganizationContext = {
    organization: Organization | null;
    isOrgDialogOpen: boolean;
    setIsOrgDialogOpen: Dispatch<SetStateAction<boolean>>;
    userOrganizations: Array<BasicOrganizationData>;
    updateOrganization: (org: string) => void;
    totalOrganizations: number;
    error: ApiError | null;
    hasPreloaded: boolean;
    isLoading: boolean;
    isLoadingOrg: boolean;
};

export const OrganizationContext = createContext<IOrganizationContext | undefined>(undefined);

export const useOrganizationContext = (): IOrganizationContext => {
    const value = useContext(OrganizationContext);

    if (!value) {
        throw new Error(
            rccError(
                '<RccContextProvider/> is most likely not wrapped around the component / provider you are trying to use. To use OrganizationContext, it must be wrapped in <OrganizationContextProvider/>',
            ),
        );
    }

    return value;
};
