import { createContext, useContext } from 'react';
import { UpdateUserPreferences, UserPreferences } from 'src/types/user-preferences.type';
import { ApiError } from '..';
import { rccError } from 'src/utils/error.utils';
import { UserPreferencesProvider } from 'src/components/user-settings/preferences/UserPreferencesProvider';

export type IUserPreferencesContext = {
    userPreferences: UserPreferences;
    isUserPreferencesLoading: boolean;
    isUserPreferencesMutating: boolean;
    isUserPreferencesValidating: boolean;
    userPreferencesMutationError: ApiError;
    getUserPreferencesError: ApiError;
    updateUserPreferences: (preferences: UpdateUserPreferences) => Promise<void>;
};

export const UserPreferencesContext = createContext<IUserPreferencesContext | undefined>(undefined);

export const useUserPreferencesContextInternal = (): IUserPreferencesContext => {
    const value = useContext(UserPreferencesContext);

    if (!value) {
        throw new Error(
            rccError(
                '<RccContextProvider/> is most likely not wrapped around the component / provider you are trying to use. To use UserPreferencesContext, it must be wrapped in <UserPreferencesProvider/>',
            ),
        );
    }

    return value;
};

export const useUserPreferencesContext = () => {
    const {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesMutating,
        userPreferencesMutationError,
        getUserPreferencesError,
    } = useUserPreferencesContextInternal();

    return {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesMutating,
        userPreferencesMutationError,
        getUserPreferencesError,
    };
};
