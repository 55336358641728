import { Link } from '@mui/material';
import { MutableRefObject } from 'react';
import { SwitcherMenu } from 'src/components/common/Switcher/SwitcherMenu';
import { SwitcherMenuItem } from 'src/components/common/Switcher/SwitcherMenuItem';
import { AppConstants } from 'src/constants/app';
import { useApplicationsContext, useOrganizationContext } from 'src/contexts';
import { useRecentApplications } from 'src/fetchers/user-recent-applications.fetcher';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { SharedApplicationModel } from 'src/types';
import { getContextRedirectUrl } from 'src/utils/navigation.utils';
import { ApplicationSwitcherOption } from './ApplicationSwitcherOption';

export const ApplicationSwitcherMenu = ({
    applications,
    anchorRef,
    menuOpen,
    onMenuClose,
}: {
    applications: SharedApplicationModel[];
    anchorRef: MutableRefObject<HTMLButtonElement | null>;
    menuOpen: boolean;
    onMenuClose: () => void;
}) => {
    const { t } = useTranslationNs();
    const { application, setIsAppDialogOpen } = useApplicationsContext();
    const { organization } = useOrganizationContext();
    const { pushRecentApplication } = useRecentApplications();

    const handleAppClick = (selectedAppName: string) => {
        pushRecentApplication(selectedAppName, organization);
        onMenuClose();
    };

    const handleViewAll = () => {
        onMenuClose();
        setIsAppDialogOpen(true);
    };

    const allApps = applications.slice(0, AppConstants.AppMenuMax).map((app) => {
        const appDisplayName = app.displayName || app.name;
        return (
            <SwitcherMenuItem
                selected={app.name === application?.name}
                key={app.name}
                component={Link}
                href={getContextRedirectUrl(
                    organization?.organizationName,
                    app.baseUrl || app.redirectUrl,
                )}
                title={appDisplayName}
                target='_blank'
                onClick={() => handleAppClick(app.name)}
            >
                <ApplicationSwitcherOption app={app} />
            </SwitcherMenuItem>
        );
    });

    return (
        <SwitcherMenu
            anchorRef={anchorRef}
            ctaTitle={t('APPLICATION_CONTEXT.MENU.MANAGE_ALL_APPS')}
            menuTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            menuOpen={menuOpen}
            onCtaClick={handleViewAll}
            onMenuClose={onMenuClose}
        >
            {allApps}
        </SwitcherMenu>
    );
};
