import { SystemStyleObject } from '@mui/system';

export const getSwitcherStatesSx = (): SystemStyleObject => ({
    '&.Mui-selected': {
        backgroundColor: 'action.selected',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'action.hover',
    },
    '&.Mui-selected.Mui-focusVisible, &.Mui-selected:hover': {
        backgroundColor: 'action.focus',
    },
});
