import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';
import { getFormattedAddress, UserAddress } from 'src/types';
import TextFieldControlledInput, {
    ReadOnlyField,
} from 'src/components/common/ControlledInput/TextFieldControlledInput';
import { SettingsPanel } from 'src/components/user-settings/common/SettingsPanel';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import {
    UserAddressFormProps,
    UserAddressSchema,
} from 'src/components/user-settings/profile/info/PersonalInfo/ProfileForm/profile-form.schema';
import { ThemeValues } from 'src/constants/theme-values';
import { useUserProfileContextInternal } from 'src/contexts/UserProfileContext';
import useCountryOptions from 'src/hooks/useCountries';

const AddressForm = () => {
    const { t } = useTranslationNs({ keyPrefix: 'COMMON.ADDRESS' });
    const [editMode, setEditMode] = useState(false);
    const { userProfile, refreshUserProfile, updateUserProfile, userProfileLoading } =
        useUserProfileContextInternal();
    const theme = useTheme();

    const { setSnackOptions } = useSnackbarContext();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isDirty, isSubmitting, isValidating },
    } = useForm<UserAddressFormProps>({
        defaultValues: userProfile?.address,
        resolver: zodResolver(UserAddressSchema),
    });

    useEffect(() => {
        resetForm();
    }, [userProfile]);

    const resetForm = () => {
        reset(userProfile?.address);
        setEditMode(false);
    };

    const onSubmit: SubmitHandler<UserAddress> = (formData) => {
        if (!userProfile) {
            return;
        }

        const updatedData = { ...userProfile, address: { ...userProfile.address, ...formData } };

        return updateUserProfile(updatedData)
            .then(() => {
                refreshUserProfile();
            })
            .catch((error: AxiosError) => {
                setSnackOptions({
                    open: true,
                    color: 'error',
                    message: error.message,
                });
            })
            .finally(() => {
                setEditMode(false);
            });
    };

    const countryOptions = useCountryOptions();

    const maskAddress = (address: UserAddress) => {
        return {
            ...address,
            street: address.street?.replaceAll(/./g, '*'),
            addressLine2: address.addressLine2?.replaceAll(/./g, '*'),
        };
    };

    const loading = useCallback(() => {
        return userProfileLoading || !userProfile || isSubmitting || isValidating;
    }, [userProfileLoading, userProfile, isSubmitting, isValidating]);

    return (
        <SettingsPanel
            onSave={handleSubmit(onSubmit)}
            onCancel={() => resetForm()}
            editMode={editMode}
            setEditMode={setEditMode}
            title={t('LABEL')}
            icon={<PlaceIcon />}
            loadingButton={loading()}
            cancelDisabled={loading()}
            disabled={!isDirty}
        >
            {userProfile && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    {editMode ? (
                        <Grid container spacing={2}>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '100%',
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='street'
                                    errors={errors}
                                    disabled={loading()}
                                    fullWidth
                                    autoComplete='street-address'
                                    label={t('STREET_ADDRESS')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '100%',
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='addressLine2'
                                    errors={errors}
                                    disabled={loading()}
                                    fullWidth
                                    autoComplete='address-line2'
                                    label={t('ADDRESS_LINE_2')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '50%',
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='city'
                                    errors={errors}
                                    disabled={loading()}
                                    fullWidth
                                    autoComplete='address-level2'
                                    label={t('CITY')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '50%',
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='county'
                                    errors={errors}
                                    disabled={loading()}
                                    fullWidth
                                    autoComplete='address-level1'
                                    label={t('COUNTY')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '50%',
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='state'
                                    errors={errors}
                                    disabled={loading()}
                                    fullWidth
                                    autoComplete='address-level1'
                                    label={t('STATE')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '50%',
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='postalCode'
                                    errors={errors}
                                    disabled={loading()}
                                    fullWidth
                                    autoComplete='postal-code'
                                    label={t('POSTAL')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '100%',
                                }}
                            >
                                <Controller
                                    render={({ field }) => (
                                        <FormControl fullWidth error={Boolean(errors.country)}>
                                            <InputLabel>{t('COUNTRY')}</InputLabel>
                                            <Select
                                                {...field}
                                                error={Boolean(errors.country)}
                                                fullWidth
                                                disabled={loading()}
                                                label={t('COUNTRY')}
                                                autoComplete='country'
                                            >
                                                {countryOptions.map((country) => (
                                                    <MenuItem
                                                        key={country.name}
                                                        value={country.name}
                                                    >
                                                        {country.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                    name='country'
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    [theme.breakpoints.down(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '100%',
                                    },
                                    [theme.breakpoints.up(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '50%',
                                    },
                                }}
                            >
                                <ReadOnlyField
                                    value={getFormattedAddress(maskAddress(userProfile.address))}
                                    label={t('LABEL')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    [theme.breakpoints.down(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '100%',
                                    },
                                    [theme.breakpoints.up(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '50%',
                                    },
                                }}
                            >
                                <ReadOnlyField
                                    value={userProfile.address.county}
                                    label={t('COUNTY')}
                                />
                            </Grid>
                        </Grid>
                    )}
                </form>
            )}
        </SettingsPanel>
    );
};

export default AddressForm;
