import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SlideProps, Snackbar, SnackbarOrigin } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton } from '@mui/material';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import { createContext } from 'react';
import useMobileView from 'src/hooks/useMobileView';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction='left' />;
}

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type SnackOptions = Partial<
    Override<
        Pick<SnackbarProps, 'message' | 'anchorOrigin' | 'color' | 'autoHideDuration' | 'open'>,
        { color: AlertColor | undefined }
    >
>;

export const enum SnackHideDurations {
    Shorter = 2500,
    Temporary = 5000,
}

const DefaultSnackOptions: SnackOptions = {
    message: '',
    anchorOrigin: { horizontal: 'right', vertical: 'top' },
    color: 'info',
    autoHideDuration: 5000,
    open: false,
};

type SnackState = {
    snackOptions: SnackOptions;
    setSnackOptions: (options: SnackOptions) => void;
};

const SnackbarContext = createContext<SnackState | undefined>(undefined);

export const useSnackbarContext = (): SnackState => {
    const context = useContext(SnackbarContext);

    if (!context) {
        throw new Error(
            '<RccContextProvider/> is most likely not wrapped around the component / provider you are trying to use. To use SnackbarContext, it must be wrapped in <SnackbarContextProvider/>',
        );
    }

    return context;
};

const AppSnackbar = ({
    snackOptions,
    handleSnackClose,
}: {
    snackOptions: SnackOptions;
    handleSnackClose: () => void;
}) => {
    return (
        <Snackbar
            open={snackOptions.open}
            anchorOrigin={snackOptions.anchorOrigin}
            color={snackOptions.color}
            onClose={handleSnackClose}
            autoHideDuration={snackOptions.autoHideDuration}
            ClickAwayListenerProps={{
                onClickAway: () => {},
            }}
            TransitionComponent={SlideTransition}
        >
            <Alert
                action={
                    <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={handleSnackClose}
                    >
                        <CloseIcon fontSize='inherit' />
                    </IconButton>
                }
                severity={snackOptions.color}
            >
                {snackOptions.message}
            </Alert>
        </Snackbar>
    );
};

export const SnackbarContextProvider = ({ children }: { children: ReactNode }) => {
    const [snackOptions, setSnackOptions] = useState<SnackOptions>({ ...DefaultSnackOptions });
    const defaultOptions = useRef({ ...DefaultSnackOptions });
    const isMobile = useMobileView();

    const mergeSnackOptions = useCallback(
        (options: SnackOptions) => {
            setSnackOptions({
                ...defaultOptions.current,
                ...options,
            });
        },
        [setSnackOptions],
    );

    const handleSnackClose = () => {
        mergeSnackOptions({ open: false });
    };

    const snackBarContextValue = useMemo(
        () => ({
            snackOptions,
            setSnackOptions: mergeSnackOptions,
        }),
        [snackOptions, mergeSnackOptions],
    );

    useEffect(() => {
        const topRight: SnackbarOrigin = { horizontal: 'right', vertical: 'top' };
        const bottomCenter: SnackbarOrigin = { horizontal: 'center', vertical: 'bottom' };
        defaultOptions.current = {
            ...DefaultSnackOptions,
            anchorOrigin: isMobile ? bottomCenter : topRight,
        };
    }, [isMobile]);

    return (
        <SnackbarContext.Provider value={snackBarContextValue}>
            <AppSnackbar snackOptions={snackOptions} handleSnackClose={handleSnackClose} />
            {children}
        </SnackbarContext.Provider>
    );
};
