import { useEffect } from 'react';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { RccLanguage } from 'src/types';
import { rccError } from 'src/utils/error.utils';

export const RccLanguageOperator = ({ language }: { language: RccLanguage }) => {
    const { i18n } = useTranslationNs();

    useEffect(() => {
        (async () => {
            try {
                await i18n.changeLanguage(language);
            } catch (e) {
                throw rccError('Please use a value of type RccLanguage');
            }
        })();
    }, [language]);

    return null;
};
