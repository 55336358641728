import { CircularProgress, Stack, SxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const SwitcherStartIcon = ({
    isLoading,
    startIcon,
    sx = {},
}: {
    isLoading: boolean;
    startIcon?: ReactNode;
    sx?: SxProps;
}): ReactNode => {
    const theme = useTheme();

    const innerNode = isLoading ? (
        <CircularProgress
            sx={{
                color: theme.palette.action.active,
            }}
            size='16px'
        />
    ) : (
        startIcon
    );

    return (
        <Stack justifyContent='center' alignItems='center' sx={sx}>
            {innerNode}
        </Stack>
    );
};
