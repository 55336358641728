import { useRccContext, useUserProfileContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Stack } from '@mui/material';
import { TextInput } from 'src/components/common/TextInput/TextInput';
import { useRef, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { SettingsPanelBox, SettingsPanel } from 'src/components/user-settings/common/SettingsPanel';
import { updateUserProfileSelf } from 'src/fetchers/user-profile.fetchers';
import { useOrganizationContext } from 'src/contexts';
import { useUserProfileContextInternal } from 'src/contexts/UserProfileContext';
import { ErrorInterface, formError, validateRequired } from 'src/utils/form.utils';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';
import Skeleton from '@mui/material/Skeleton';

interface UserProfileErrors extends ErrorInterface {
    firstName: null | formError.required;
    lastName: null | formError.required;
}

const PersonalInfo = () => {
    const { isInternalUser } = useRccContext();
    const { userProfile } = useUserProfileContext();
    const { refreshUserProfile } = useUserProfileContextInternal();
    const { organization } = useOrganizationContext();
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PROFILE',
    });
    const { t: commonNsTranslate } = useTranslationNs({ keyPrefix: 'COMMON' });
    const [editMode, setEditMode] = useState<boolean>(false);
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);

    const [errors, setErrors] = useState<UserProfileErrors>({
        firstName: null,
        lastName: null,
    });

    const { setSnackOptions } = useSnackbarContext();

    const handleResponse = (message: string, status: 'success' | 'error') => {
        setSnackOptions({
            open: true,
            message: message,
            color: status,
        });
    };

    function saveForm() {
        let updatedProfile = userProfile
        updatedProfile.givenName = firstNameRef.current?.value;
        updatedProfile.familyName = firstNameRef.current?.value;
        
        updateUserProfileSelf(updatedProfile, organization.organizationName)
            .then(() => {
                handleResponse(commonNsTranslate('ALERTS.UPDATE_SUCCESS'), 'success');
            })
            .catch(() => {
                handleResponse(commonNsTranslate('ALERTS.UPDATE_FAILED'), 'error');
            })
            .finally(() => {
                refreshUserProfile();
                setEditMode(false);
            });
    }

    function cancelForm() {
        setEditMode(false);
    }

    if (!userProfile) {
        return <Skeleton variant='rounded' height={160} />;
    }

    if (isInternalUser) {
        return (
            <SettingsPanelBox title={t('PERSONAL_INFO')} icon={<PersonIcon />}>
                <Stack gap={'8px'}>
                    <TextInput
                        label={t('FIRST_NAME')}
                        defaultValue={userProfile.givenName}
                        disabled
                    />
                    <TextInput
                        label={t('LAST_NAME')}
                        defaultValue={userProfile.familyName}
                        disabled
                    />
                </Stack>
            </SettingsPanelBox>
        );
    }

    return (
        <SettingsPanel
            title={t('PERSONAL_INFO')}
            icon={<PersonIcon />}
            editMode={editMode}
            setEditMode={setEditMode}
            disabled={Boolean(errors.firstName) || Boolean(errors.lastName)}
            onSave={saveForm}
            onCancel={cancelForm}
        >
            <Stack gap={'8px'}>
                <TextInput
                    ref={firstNameRef}
                    required
                    label={t('FIRST_NAME')}
                    error={errors.firstName === formError.required}
                    onChange={(e) => {
                        validateRequired(e.target.value, 'firstName', errors, setErrors);
                    }}
                    editMode={editMode}
                    defaultValue={userProfile.givenName}
                />
                <TextInput
                    ref={lastNameRef}
                    required
                    label={t('LAST_NAME')}
                    error={errors.lastName === formError.required}
                    onChange={(e) => {
                        validateRequired(e.target.value, 'lastName', errors, setErrors);
                    }}
                    editMode={editMode}
                    defaultValue={userProfile.familyName}
                />
            </Stack>
        </SettingsPanel>
    );
};

export default PersonalInfo;
