import { createContext, useContext } from 'react';
import { RecentOrganization } from 'src/types/user-preferences.type';
import { ApiError, Organization } from '..';
import { rccError } from 'src/utils/error.utils';

export type IOrganizationsCacheContext = {
    recentOrganizations: RecentOrganization[];
    recentOrganizationsMutationError: ApiError;
    isRecentOrganizationsLoading: boolean;
    getRecentOrganizationsError: ApiError;
    isRecentOrganizationsMutating: boolean;
    updateUserRecentOrganizations: (organization: Organization) => Promise<void>;
    deleteEffectiveOrgsCache: () => Promise<void>;
};

export const OrganizationsCacheContext = createContext<IOrganizationsCacheContext | undefined>(
    undefined,
);

export const useOrganizationsCacheContextInternal = (): IOrganizationsCacheContext => {
    const value = useContext(OrganizationsCacheContext);

    if (!value) {
        throw new Error(
            rccError(
                '<RccContextProvider/> is most likely not wrapped around the component / provider you are trying to use. To use OrganizationsCacheContext, it must be wrapped in <OrganizationsCacheProvider/>',
            ),
        );
    }

    return value;
};

export const useOrganizationsCacheContext = () => {
    const {
        recentOrganizations,
        recentOrganizationsMutationError,
        isRecentOrganizationsLoading,
        getRecentOrganizationsError,
        isRecentOrganizationsMutating,
    } = useOrganizationsCacheContextInternal();

    return {
        recentOrganizations,
        recentOrganizationsMutationError,
        isRecentOrganizationsLoading,
        getRecentOrganizationsError,
        isRecentOrganizationsMutating,
    };
};
