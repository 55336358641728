import { Close } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, List, SwipeableDrawer, Typography } from '@mui/material';
import { ReactNode } from 'react';
import {
    SwipeableDrawerFooter,
    SwipeableDrawerHeader,
} from 'src/components/common/SwipeableDrawer';
import { ThemeValues } from 'src/constants/theme-values';

export const SwitcherDrawer = ({
    menuOpen,
    menuTitle,
    menuCloseTitle,
    ctaTitle,
    onMenuOpen,
    onMenuClose,
    onCtaClick,
    children,
}: {
    menuOpen: boolean;
    menuTitle: string;
    menuCloseTitle: string;
    onMenuOpen: () => void;
    onMenuClose: () => void;
    ctaTitle: string;
    onCtaClick: () => void;
    children: ReactNode;
}) => {
    return (
        <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor='bottom'
            open={menuOpen}
            onClose={onMenuClose}
            onOpen={onMenuOpen}
            sx={{
                '.MuiPaper-root.MuiDrawer-paper': {
                    borderTopRightRadius: ThemeValues.BorderRadius,
                    borderTopLeftRadius: ThemeValues.BorderRadius,
                },
            }}
        >
            <SwipeableDrawerHeader>
                <Box
                    padding='8px 16px'
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '1.125rem',
                        }}
                        variant='h6'
                    >
                        {menuTitle}
                    </Typography>
                    <IconButton title={menuCloseTitle} onClick={onMenuClose}>
                        <Close />
                    </IconButton>
                </Box>
            </SwipeableDrawerHeader>
            <List sx={{ maxHeight: '70vh', overflowY: 'auto', padding: '0px 16px 8px 16px' }}>
                {children}
            </List>
            <Divider />
            <SwipeableDrawerFooter>
                <Button
                    size='large'
                    onClick={onCtaClick}
                    sx={{
                        borderRadius: ThemeValues.BorderRadius,
                        textTransform: 'none',
                        width: '100%',
                        color: 'text.primary',
                        borderColor: 'text.primary',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                            borderColor: 'text.primary',
                        },
                    }}
                    variant='outlined'
                >
                    {ctaTitle}
                </Button>
            </SwipeableDrawerFooter>
        </SwipeableDrawer>
    );
};
