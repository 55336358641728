import {
    ExtendButtonBaseTypeMap,
    ListItem,
    ListItemButton,
    ListItemButtonProps,
    MenuItemTypeMap,
    useTheme,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';
import { ThemeValues } from 'src/constants/theme-values';
import { getSwitcherStatesSx } from './SwitcherItemSx';

export const SwitcherDrawerItem = (
    props: { href?: string } & OverrideProps<ExtendButtonBaseTypeMap<MenuItemTypeMap>, 'a'> &
        ListItemButtonProps,
) => {
    const theme = useTheme();
    const { children, sx, ...rest } = props;

    return (
        <ListItem
            sx={{
                padding: '4px 0px',
            }}
        >
            <ListItemButton
                sx={{
                    borderRadius: ThemeValues.BorderRadius,
                    padding: '8px 16px 8px 8px',
                    ...theme.typography.subtitle1,
                    ...getSwitcherStatesSx(),
                    ...sx,
                }}
                {...rest}
            >
                {children}
            </ListItemButton>
        </ListItem>
    );
};
