import { ArrowDropDown } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Switcher, SwitcherProps } from 'src/components/common/Switcher/Switcher';
import { SwitcherMenu } from 'src/components/common/Switcher/SwitcherMenu';
import {
    getDefaultSwitcherConfig,
    SwitcherProvider,
} from 'src/components/common/Switcher/SwitcherProvider';

export type SettingsSwitcherProps = Omit<SwitcherProps, 'buttonSx' | 'labelSx'> & {
    menuContent: ReactNode;
    isMenuOpen: boolean;
    onMenuClose: () => void;
};

const getRefWidth = (element: HTMLElement) => {
    return `${element.clientWidth}px`;
};

export const SettingsSwitcher = ({
    isMenuOpen,
    onMenuClose,
    menuContent,
    ...props
}: SettingsSwitcherProps) => {
    const switcherRef = useRef<HTMLButtonElement>(null);
    const [switcherWidth, setSwitcherWidth] = useState<string>('');

    useEffect(() => {
        if (isMenuOpen) {
            setSwitcherWidth(getRefWidth(switcherRef.current));
        }
    }, [isMenuOpen, switcherRef.current]);

    return (
        <SwitcherProvider config={getDefaultSwitcherConfig()}>
            <Switcher
                ref={switcherRef}
                buttonSx={{
                    border: '1px solid',
                    borderColor: (theme: Theme) => theme.palette.action.active,
                    padding: '10px 12px',
                    width: '100%',
                    maxWidth: 'unset',
                    '.MuiLoadingButton-loadingIndicator': {
                        left: '12px',
                    },
                }}
                labelSx={{
                    fontWeight: 500,
                }}
                endIcon={
                    <ArrowDropDown
                        fontSize='small'
                        sx={{
                            color: (theme) => theme.palette.action.active,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                }
                {...props}
            />
            <SwitcherMenu
                anchorRef={switcherRef}
                menuOpen={isMenuOpen}
                onMenuClose={onMenuClose}
                menuPaperSx={{
                    width: switcherWidth,
                    maxHeight: '235px',
                }}
            >
                {menuContent}
            </SwitcherMenu>
        </SwitcherProvider>
    );
};
